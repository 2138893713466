@-webkit-viewport {
    width: device-width;
}

@-moz-viewport {
    width: device-width;
}

@-ms-viewport {
    width: device-width;
}

@-o-viewport {
    width: device-width;
}

@viewport {
    width: device-width;
}

:root {
    --primary-color: #FF916F;
    --secondary-color: #ffffff;
    --tertiary-color: #0b0b12de;
    --quaternary-color: #3E4351;
    --quinary-color: #f5f5f5;
    --senary-color: #ffcd00;
    --btn-color: #FF916F;
    --blue-color:#1f73b7;
    --link-color:#298BFF;
}

html{
    background-color: #fff!important;   
  }
  
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
    overflow-x: hidden;
    position: relative;
    font-family: "Outfit", sans-serif!important;
    color: var(--tertiary-color);
    background-color: #fff!important;
    font-size: 16px!important;
    letter-spacing: 0.8px;
}

body.modal-open {
    overflow: auto !important;
}
body.modal-open[style] {
    padding-right: 0px !important;
}

.logo {
    max-height: 70px;
    object-fit: contain;
}

.navbar-logo img {
    max-height: 80px;
    object-fit: contain;
}

.header-nav-center {
    padding: 0em 0;
    border-bottom: 1px solid rgba(11,34,56,0);
    -webkit-transition: .3s!important;
    -moz-transition: .3s!important;
    -o-transition: .3s!important;
    -ms-transition: .3s!important;
    transition: .3s!important;
    background-color: #000;
    padding-top: 1em;
  /*  position: fixed;
    left: 0;
    right: 0;
    z-index: 999;*/
}

.header-nav-center .navbar .nav-link {
    font-size: 1.2em;
    font-weight: 500;
    text-transform: capitalize;
    opacity: 0.8;
    letter-spacing: 0.5px;
}

.dropdown-toggle::after {
    display: none;
}

.hamburger-menu {
    width: 2em;
}

/* OnBoarding CSS */

.haive-logo{
    color: #0f1623;
    margin-bottom: 0;
    font-size: 1.75rem;
    font-weight: 500;
}

.onboarding-sec{
    background-image: url('./on-boarding-bg.png');
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    background-position: center center;
    background-size: cover;
    display: block;
    padding: 1.5em 2em;
}

.onboarding-left-sec{
    /* background: linear-gradient(180deg,#fff 0,#e4fbed 100%); */
    /* background: linear-gradient(180deg, #fff 0, #ffff 100%); */
    background-color: var(--secondary-color);
    width: 100%;
    /* background-image: url('./onboarding-left-bg-img.png');
    background-repeat: no-repeat; */
    position: relative;
    /* min-height: calc(100vh - 50px); */
    background-position: center center;
    background-size: cover;
    display: block;
    /* overflow:auto; */
    max-width: 700px;
    padding: 2em;
    margin: 0 auto;
    border-radius: 20px;
}

.onboarding-right-sec{
    width: 45%;
    background-image: url('./onboarding-left-bg-img.png');
    background-repeat: no-repeat;
    position: relative;
    /* min-height: 100vh; */
    min-height: calc(100vh - 50px);
    background-position: bottom;
    background-size: cover;
    display: block;
    position: relative;
    border-radius: 20px;
}

.onboarding-right-sec::before{
    position: absolute;
    content: "";
    mix-blend-mode: multiply;
    background-image: linear-gradient(45deg, #02050E, transparent) !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
}

.onboarding-box{
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: center;
    min-height: calc(100vh - 50px);
    align-items: center;
}

.onboarding-card{
    padding: 0em 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 95%;
}

/* .onboarding-logo-sec .navbar-brand{
    margin-left: 1em;
} */

.onboarding-subscription-payment-header .navbar-brand{
    margin-left: 0em;
} 

.onboarding-right-img-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1.5em;
    position: relative;
}

.onboarding-right-img{
    max-width: 10em;
}

.onboarding-title-sec h2{
    font-size: 1.8em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0.5em;
    /* text-align: center; */
}

.onboarding-title-sec p{
    font-size:0.9em;
    margin-bottom: 0;
    color: var(--quaternary-color);
    /* text-align: center; */
}

.onboarding-select-services-box{
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 1em;
    margin-top: 2em;
}

.onboarding-select-services-box label.radio-card {
    cursor: pointer;
    line-height: 0;
}
.onboarding-select-services-box label.radio-card .card-content-wrapper {
    /* box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04); */
    position: relative;
    transition: 200ms linear;
    border-style: solid;
    border-color: transparent;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 20px;
}
.onboarding-select-services-box label.radio-card .check-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    transition: 200ms linear;
    position: absolute;
    right: 15px;
    top:15px;
    opacity: 0;
    visibility: hidden;
}

.onboarding-select-services-box label.radio-card .check-icon:before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear;
    opacity: 0;
}
.onboarding-select-services-box label.radio-card input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.onboarding-select-services-box label.radio-card input[type='radio']:checked + .card-content-wrapper {
    background: var(--secondary-color);
    border-style: solid;
    border-color: var(--blue-color);
}
.onboarding-select-services-box label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon {
    background: var(--blue-color);
    border-color: var(--blue-color);
    transform: scale(1.1);
    opacity: 1;
    visibility: visible;
}
.onboarding-select-services-box label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon:before {
    transform: scale(1);
    opacity: 1;
}
.onboarding-select-services-box label.radio-card input[type='radio']:focus + .card-content-wrapper .check-icon {
    box-shadow:0 0 0 4px rgb(31 115 183 / 20%);
    border-color: var(--blue-color);
}

.onboarding-select-services-box label.radio-card .card-content h4 {
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.5em;
}
.onboarding-select-services-box label.radio-card .card-content p {
    font-size: .9em;
    color: var(--quaternary-color);
    opacity: .8;
    margin: 0;
    font-weight: 400;
    line-height: 1.8;
}

.onboarding-right-box{
    padding: 2em 0;
}

.onboarding-select-services-box label.radio-card .card-content{
    /* display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 100%;
    padding: 3em 1em;
    gap: 1em; */
    display: grid;
    padding: 1.5em;
    grid-template-columns: 100px auto;
}

.onboarding-right-img{
    width: 80%;
}

.onboarding-btn-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3em;
}

.onboarding-btn{
    border-radius: 10px!important;
    border-width: 0px;
    border: 0!important;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    color: var(--secondary-color)!important;
    background-image: linear-gradient(to top, #FF6F6F 0px, #ff8073 8px, #FF6F6F 8px, #FFB179 100%);
    text-decoration: none!important;
    display: inline-block;
    padding: 0.6em 3em!important;
    padding-bottom: 1em!important;
    font-size: 0.9em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
}

.onboarding-btn:after {
    content: "";
    width: 0;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 5px 5px;
    transition: all 0.35s ease 0s;
    background-color: #fff;
}

.onboarding-btn:hover:after {
   width: 100%;
}

.modal-close:before,
.modal-close:after{
    display: none!important;
}

/* .btn:before,
.btn:after{
    content: '';
    background: var(--secondary-color);
    height: 2px;
    width: 0;
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.4s ease all;
}
.btn:after{
    top: auto;
    right: auto;
    left: 0;
    bottom: 0;
}
.btn:hover:before,
.btn:hover:after{ 
    width: 100%;
    transition: 800ms ease all; 
}  */

.onboarding-back-btn{
    border-radius: 10px!important;
    border-width: 1px;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    color: var(--tertiary-color)!important;
    border-color: transparent!important;
    background-color: transparent!important;
    text-decoration: underline!important;
    display: flex!important;
    align-items: center;
    gap: 0.5em;
    padding: 0.6em 0em!important;
    font-size: 1em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
}

.onboarding-back-btn:hover {
    -webkit-transform: translate3d(0,-5px,0);
    -o-transform: translate3d(0,-5px,0);
    -ms-transform: translate3d(0,-5px,0);
    -moz-transform: translate3d(0,-5px,0);
    transform: translate3d(0,-5px,0);
    color: var(--tertiary-color)!important;
    border-color: transparent!important;
    background-color: transparent!important;
    text-decoration: underline!important;
}

.onboarding-on-premise-btn-sec{
    display: flex;
    align-items: center;
    justify-content:space-between;
    margin-top: 2em;
}

.onboarding-installation-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    /* height: 100%; */
    /* height: calc(100vh - 0px); */
    padding: 0 2em;
}

.onboarding-installation-content h3{
    font-size: 1.4em;
    font-weight: 500;
    line-height: 1.5;
    color: var(--secondary-color);
    position: relative;
    margin-bottom: 0.5em;
}

.onboarding-installation-content p{
    font-size: 0.95em;
    color: #FFFFFFB5;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
}

.onboarding-installation-list{
    margin-top: 2em;
}

.onboarding-installation-list svg{
    margin-top: 0.4em;
}

.onboarding-installation-list li{
    grid-template-columns: 40px auto;
    display: grid;
    padding-bottom: 1em;
    font-size: 0.95em;
    color: var(--secondary-color);
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
}

.onboarding-installation-list li:last-child{
    padding-bottom: 0;
}

.onboarding-steps-card{
    /* padding: 0em 1em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 90%; */
    /* padding-bottom:2em; */
    padding-top: 2em;
}

.onboarding-onpremise-title-sec h2{
    font-size: 1.8em;
    font-weight: 600;
    color: var(--tertiary-color);
    margin-bottom: 0.5em;
}

.onboarding-onpremise-title-sec p{
    font-size:0.9em;
    margin-bottom: 0;
    color: var(--quaternary-color);
}

.onboarding-form{
    padding-top: 2em;
}

.onboarding-form .form-label{
    font-size: 0.9em;
    font-weight: 400;
    color: var(--quaternary-color);
    line-height: 1.5;
}

.onboarding-form .form-label span{
    color: #E2434D;
}

.onboarding-form .form-control{
    border: 1px solid #F3F2ED!important;
    background-color: var(--secondary-color)!important;
    border-radius: 10px!important;
    /* background-color: transparent!important; */
    height: 3em;
    font-size: 0.9em;
    font-weight: 400;
}

.onboarding-form textarea.form-control{
    height: auto;
}

.onboarding-form .form-control:focus{
    box-shadow: none!important;
}

.onboarding-form .form-control::placeholder{
   color: #50506B80;
   font-size: 0.9em;
   font-weight: 400;
}

.onboarding-form .input-group .form-control{
    border-left:0!important;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}

.onboarding-form .input-group-text{
    border-radius: 10px!important;
    border: 1px solid #F3F2ED!important;
    background-color: var(--primary-color);
    border-right: 0!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    font-size:0.9em;
    margin-bottom: 0;
    color:var(--secondary-color);
}

.onboarding-form .register-input-group .input-group-text{
    border: 1px solid #F3F2ED!important;
    background-color: var(--secondary-color);
    border-left: 0!important;
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.onboarding-form .register-input-group .form-control{
    border: 1px solid #F3F2ED!important;
    border-right: 0!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.onboarding-success-box{
    width: 100%;
    background-image: url('./on-boarding-bg.png');
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    background-position: center center;
    background-size: cover;
    display: block;
    overflow:auto;
}

.on-premise-success-box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
}

.onboarding-success-box{
    position: relative;
}

.on-premise-success-card{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
}

.on-premise-success-info h4{
    font-size: 2.5em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.5em;
    text-align: center;
} 

.on-premise-success-info p{
    font-size:0.9em;
    font-weight: 500;
    line-height: 1.8;
    margin-bottom: 0;
    color: var(--quaternary-color);
    text-align: center;
}

.on-premise-success-info{
    margin-bottom: 2em;
}

.onboarding-form .flex-data{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}

.onboarding-form .flex-data a{
    color: var(--link-color);
    text-decoration: underline!important;
    font-weight: 500!important;
    font-size: 0.9em;
}

.onboarding-form .flex-data input[type="checkbox"]:checked {
    background-color: var(--primary-color);
    box-shadow: none;
    width: 1.2em;
    height: 1.2em;
    margin-top:0.2em;
    border-color: var(--primary-color);
}

.onboarding-form .flex-data input[type="checkbox"]{
    width: 1.2em;
    height: 1.2em;
    margin-top:0.2em;
}


.onboarding-form .flex-data input[type="checkbox"]{
    box-shadow: none;
}

.ai-as-service-btn-sec{
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboarding-link-sec{
    margin-top: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-weight: 400;
    font-size: 1em;
    justify-content: center;
}

.onboarding-link-sec-1{
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
}

.onboarding-link-sec-1 a{
    font-weight: 500;
    font-size: 0.9em;
    color: var(--link-color);
    text-decoration: underline!important;
}

.onboarding-link-sec a{
    font-weight: 500;
    font-size: 0.9em;
    color: var(--link-color);
    text-decoration: underline!important;
}

.onboarding-link-sec a:hover{
    text-decoration: underline!important;
    color: var(--blue-color);
}

.onboarding-link-sec-1 a:hover{
    text-decoration: underline!important;
    color: var(--blue-color);
}
.onboarding-form .flex-data a:hover{
    text-decoration: underline!important;
}

/* Steppers Count CSS */

.stepper-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom:2em;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #213567;
    width: 87%;
    top: 30px;
    /* left: -52%; */
    left: -85%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #213567;
    width: 87%;
    top: 30px;
    left: 48%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    color: var(--secondary-color);
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgb(100 116 139);
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
    font-weight: bold;
  }
  
  .stepper-item.completed .step-counter {
    background-color: var(--primary-color);
    color: var(--tertiary-color);
    font-weight: 500;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid var(--secondary-color);
    width: 100%;
    top: 30px;
    /* left: 47%; */
    left: 23%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }

  .onboarding-ai-as-service-img-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  .onboarding-ai-as-service-img{
   max-width: 10em;
  }

  .onboarding-choose-solution-box{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 1em;
    margin-top: 2em;
}

.onboarding-choose-solution-box label.radio-card {
    cursor: pointer;
    line-height: 0;
}
.onboarding-choose-solution-box label.radio-card .card-content-wrapper {
    /* box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04); */
    position: relative;
    transition: 200ms linear;
    border-style: solid;
    border-color: transparent;
    padding: 1em 1.5em;
    background-color:var(--secondary-color);
}
.onboarding-choose-solution-box label.radio-card .card-content-wrapper .card-content{
    display: flex;
    align-items: center;
    justify-content: center;
}

.onboarding-choose-solution-box label.radio-card .check-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    transition: 200ms linear;
    position: absolute;
    right: -10px;
    top:-10px;
    opacity: 0;
    visibility: hidden;
}

.onboarding-choose-solution-box label.radio-card .check-icon:before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear;
    opacity: 0;
}
.onboarding-choose-solution-box label.radio-card input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.onboarding-choose-solution-box label.radio-card input[type='radio']:checked + .card-content-wrapper {
    background: rgba(0,0,0,0.05);
    border-style: solid;
    border-color: var(--btn-color);
}
.onboarding-choose-solution-box label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon {
    background: var(--btn-color);
    border-color: var(--btn-color);
    transform: scale(1.1);
    opacity: 1;
    visibility: visible;
}
.onboarding-choose-solution-box label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon:before {
    transform: scale(1);
    opacity: 1;
}
.onboarding-choose-solution-box label.radio-card input[type='radio']:focus + .card-content-wrapper .check-icon {
    box-shadow: 0 0 0 4px rgb(255 153 2 / 20%);
    border-color: var(--btn-color);
}

.choose-solution-footer-sec{
    margin-top: 1em;
}

.onboarding-fine-tuning-box{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1em;
}

.onboarding-fine-tuning-card{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5em;
    text-align: center;
    background-color: var(--secondary-color);
    padding: 2em 1.5em;
    cursor: pointer;
    border-radius: 20px;
}

.onboarding-fine-tuning-info h4{
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.8em;
}

.onboarding-fine-tuning-info p{
    font-size: .9em;
    color: var(--quaternary-color);
    opacity: .8;
    margin: 0;
    font-weight: 400;
    line-height: 1.8;
}

.modal-close{
    background-color: transparent!important;
    padding: 0!important;
    border: 0!important;
    position: absolute;
    right: 15px;
    top: 15px;
}

.modal-close .close-icon{
    width:2em;
}

.modal-close:focus{
    box-shadow: none !important;
    outline:none !important;
}

/* .modal-close svg path{
    fill: #dc3545;
} */

.import-webpage-modal .modal-body h4{
    font-size: 1.1em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0em;
    /* margin-left: 1em; */
}

.onboarding-cancel-btn{
    border-radius: 10px!important;
    border-width: 1px;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    color: var(--tertiary-color)!important;
    border-color: #6666661c !important;
    background: #6666661c !important;
    text-decoration: none!important;
    display: inline-block;
    padding: 0.6em 3em!important;
    font-size: 0.85em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
    width: 100%;
}

.onboarding-cancel-btn:hover {
    /* -webkit-transform: translate3d(0,-5px,0);
    -o-transform: translate3d(0,-5px,0);
    -ms-transform: translate3d(0,-5px,0);
    -moz-transform: translate3d(0,-5px,0);
    transform: translate3d(0,-5px,0);
    color: var(--tertiary-color)!important;
    border-color: #6666661c !important;
    background-color: #6666661c !important;
    text-decoration: none!important; */
}

.onboarding-save-btn{
    border-radius: 10px !important;
    border-width: 0px;
    border: 0 !important;
    position: relative;
    transition: .5s;
    color: var(--secondary-color) !important;
    background-image: linear-gradient(to top, #FF6F6F 0px, #ff8073 8px, #FF6F6F 8px, #FFB179 100%);
    text-decoration: none !important;
    display: flex;
    align-items: center;
    gap: 0.3em;
    padding: 0.6em 3em !important;
    padding-bottom: 1em!important;
    font-size: 0.9em !important;
    line-height: 25px;
    font-weight: 500 !important;
    letter-spacing: .5px;
    text-align: center;
    justify-content:center;
    width:100%;
}

.onboarding-save-btn:after {
    content: "";
    width: 0;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 5px 5px;
    transition: all 0.35s ease 0s;
    background-color: #fff;
  }
  
  .onboarding-save-btn:hover:after {
   width: 100%;
  }


.onboarding-delete-btn{
    border-radius: 10px!important;
    border-width: 1px;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    color: var(--secondary-color)!important;;
    border-color: #6666661c !important;
    background: #eb1c0d!important;
    text-decoration: none!important;
    display: inline-block;
    padding: 0.6em 3em!important;
    font-size: 0.9em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
    width: 100%;
}

.onboarding-delete-btn:hover {
    -webkit-transform: translate3d(0,-5px,0);
    -o-transform: translate3d(0,-5px,0);
    -ms-transform: translate3d(0,-5px,0);
    -moz-transform: translate3d(0,-5px,0);
    transform: translate3d(0,-5px,0);
    color: var(--secondary-color)!important;;
    border-color: #6666661c !important;
    background-color: #eb1c0d!important;
    text-decoration: none!important;
}

.onboarding-import-webpage-modal-btn-sec{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1em;
}

.onboarding-document-upload-card{
    background: linear-gradient(146.91deg, rgba(50, 8, 159, 0) 12.31%, rgba(50, 8, 159, 0.08) 96.38%);
    /* border: 1px dashed var(--btn-color); */
    margin-bottom: 1em;
    cursor: pointer;
    border-radius: 20px;
}

.onboarding-document-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.onboarding-document-item p{
    font-size: .85em;
    color: var(--quaternary-color);
    opacity: .8;
    margin: 0;
    font-weight: 400;
    line-height: 1.8;
}

.onboarding-document-item p strong{
    color: var(--tertiary-color);
    text-decoration: underline;
    font-weight: 500;
}

.onboarding-add-fine-tuning-btn{
    border-radius: 10px !important;
    border-width: 0px;
    border: 0 !important;
    position: relative;
    /* -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s; */
    color: var(--secondary-color) !important;
    background-image: linear-gradient(to top, #FF6F6F 0px, #ff8073 8px, #FF6F6F 8px, #FFB179 100%);
    text-decoration: none !important;
    display: inline-block;
    padding: 0.6em 3em !important;
    font-size: 0.9em !important;
    line-height: 25px;
    font-weight: 500 !important;
    letter-spacing: .5px;
    display: flex!important;
    align-items: center;
    gap: 0.3em;
    position: relative;
    padding-bottom: 1em!important;
}

.onboarding-add-fine-tuning-btn:after {
    content: "";
    width: 0;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 5px 5px;
    transition: all 0.35s ease 0s;
    background-color: #fff;
}

.onboarding-add-fine-tuning-btn:hover:after {
   width: 100%;
}

.onboarding-fine-tunings-download-btn{
    background-color: transparent!important;
    border: 0!important;
    padding: 0!important;
    color: var(--btn-color)!important;
    display: flex!important;
    align-items: center;
    gap: 0.5em;
    font-size: 0.9em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
}

.onboarding-fine-tunings-edit-btn{
    background-color: transparent!important;
    border: 0!important;
    padding: 0!important;
    color: var(--btn-color)!important;
    display: flex!important;
    align-items: center;
    gap: 0.5em;
    font-size: 0.9em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
}

.onboarding-fine-tunings-delete-btn{
    background-color: transparent!important;
    border: 0!important;
    padding: 0!important;
    color: #E2434D!important;
    display: flex!important;
    align-items: center;
    gap: 0.5em;
    font-size: 0.9em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
}

.onboarding-fine-tunings-files-action-btn-sec{
    display: flex;
    align-items: center;
    gap: 1em;
}

.onboarding-fine-tunings-files-box{
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 1em;
    margin-top: 2em;
}

.onboarding-fine-tunings-files-card{
    background-color: var(--secondary-color);
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.onboarding-fine-tunings-files-name{
    font-size: 0.9em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0em;
}

.onboarding-payment-method-sec{
    padding-top: 2em;
    padding-bottom: 2em;
}

.onboarding-purchase-summary-box{
    border: 1px solid #64748B24;
    background-color: var(--secondary-color);
    padding: 1.5em;
    border-radius: 20px;
}

.onboarding-purchase-summary-header-sec{
    border-bottom: 1px solid #A3ADB847;
}

.onboarding-purchase-summary-header-sec h2{
    font-size: 1.4em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0em;
}

.onboarding-purchase-summary-choosed-plan-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0;
}

.onboarding-purchase-summary-choosed-plan-card h6{
    color: var(--tertiary-color);
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 500;
    border: 0.92px solid #A3ADB836;
    background-color: #FFF6DD;
    border-radius: 20px;
    padding: 0.5em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-transform: capitalize;
}

.onboarding-purchase-summary-choosed-plan-card p{
    margin-bottom: 0;
    font-size: 1.4em;
    font-weight: 500;
    color: var(--tertiary-color);
}

.onboarding-purchase-summary-inclusive-list{
    margin-bottom: 1em;
}

.onboarding-purchase-summary-inclusive-list li span{
    color: #50506B;
    font-weight: 500;
    font-size: 0.9em;
}

.onboarding-purchase-summary-inclusive-list li{
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding-bottom: 1em;
}

.onboarding-purchase-summary-inclusive-list li:last-child{
    padding-bottom: 0;
}

.onboarding-purchase-summary-total-amount-sec h3{
    margin-bottom: 0;
    font-size: 1.4em;
    font-weight: 500;
    color: var(--tertiary-color);
}

.onboarding-purchase-summary-total-amount-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1em;
    border-bottom: 1px solid #A3ADB847;
    padding-bottom: 1em;
}

.onboarding-purchase-summary-note-sec p{
    font-size: .9em;
    color:#64748B;
    opacity: .8;
    /* margin: 0; */
    font-weight: 400;
    line-height: 1.8;
    word-break: break-word;
}

.onboarding-purchase-summary-note-sec p+p{
    margin-bottom: 0;
}

.onboarding-purchase-summary-note-sec{
    padding-top: 2em;
}

.onboarding-purchase-summary-note-sec p a{
    color: var(--tertiary-color);
    text-decoration: underline!important;
    font-weight: 500;
}

.onboarding-purchase-summary-note-sec p a:hover{
    text-decoration: underline!important;
}

.on-premise-success-img-sec{
    margin-bottom: 2em;
}

.errorMsg {
    color: #E2434D;
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.5;
}

.onboarding-payment-method-box{
    border: 1px solid #64748B24;
    background-color: var(--secondary-color);
    padding: 1.5em;
    border-radius: 20px;
}

.onboarding-purchase-summary-header-card{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
}

.onboarding-purchase-summary-badge{
    border: 0.92px solid #A3ADB836;
    background-color: #F8F9FF;
    border-radius: 20px;
    padding: 0.5em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.onboarding-purchase-summary-badge h4{
    font-size: 0.9em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0em;
}

.onboarding-purchase-summary-badge p{
    font-size: .8em;
    color: #62CE6E;
    opacity: .8;
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
}

.onboarding-payment-method-header-sec h2 {
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0em;
}

.onboarding-payment-method-header-sec p{
    font-size: .9em;
    color: #64748B;
    opacity: .8;
    margin: 0;
    font-weight: 400;
    line-height: 1.8;
}

.onboarding-payment-method-item{
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 1em;
}

.onboarding-payment-method-card-icon{
    width: 5em;
    height: 3em;
    object-fit: contain;
}

.onboarding-payment-method-card-header-sec h4{
    color: var(--tertiary-color);
    margin-bottom: 0;
    font-weight: 500;
    font-size: 1em;
    padding-left: 1.5em;
}

.onboarding-payment-method-card-header-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.onboarding-payment-method-box label.radio-card {
    cursor: pointer;
    line-height: 0;
}
.onboarding-payment-method-box label.radio-card .card-content-wrapper {
    position: relative;
    transition: 200ms linear;
    border-style: solid;
    border-color: transparent;
    padding: 1em 1.5em;
    background-color: var(--secondary-color);
    border: 1px solid #BAC1CA73;
}

.onboarding-payment-method-box label.radio-card .check-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    transition: 200ms linear;
    position: absolute;
    top: 30px;
    left: 2%;
    right: 0;
}

.onboarding-payment-method-box label.radio-card .check-icon:before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear;
    opacity: 0;
}
.onboarding-payment-method-box label.radio-card input[type='radio'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.onboarding-payment-method-box label.radio-card input[type='radio']:checked + .card-content-wrapper {
    /* background: rgba(0,0,0,0.05); */
    border-style: solid;
    border-color: var(--btn-color);
    border-radius: 20px;
}
.onboarding-payment-method-box label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon {
    background: var(--btn-color);
    border-color: var(--btn-color);
}
.onboarding-payment-method-box label.radio-card input[type='radio']:checked + .card-content-wrapper .check-icon:before {
    transform: scale(1);
    opacity: 1;
}
.onboarding-payment-method-box label.radio-card input[type='radio']:focus + .card-content-wrapper .check-icon {
    box-shadow: 0 0 0 4px rgb(255 153 2 / 20%);
    border-color: var(--btn-color);
}

.onboarding-payment-method-card-body-sec{
    margin-top: 2em;
}

.react-dropdown-select-item{
    line-height: 1.5;
}

.react-dropdown-select-input{
    border-radius: 10px!important;
    background-color: transparent!important;
    width: 100%!important;
    height: 2em;
}

.react-dropdown-select{
    border: 1px solid #F3F2ED!important;
    border-radius: 10px!important;
    background-color: transparent!important;
   min-height: 46px!important; 
}

.react-dropdown-select:focus{
    box-shadow: none!important;
    outline: none!important;
}

.react-dropdown-select-content span{
    padding-top: 1em;
    position: absolute;
    top: 6px;
}

.onboarding-subscription-plans-sec .nav-link.active{
    border-radius: 10px!important;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    color: var(--secondary-color)!important;
    border-color: var(--btn-color)!important;
    background: var(--btn-color)!important;
    text-decoration: none!important;
}

.onboarding-subscription-plans-sec .nav-link{
    padding: 0.8em 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: var(--tertiary-color);
    font-size: 1em;
    font-weight: 500;
    border-radius: 10px!important;
}

.onboarding-subscription-plans-sec .nav-link h4{
    font-size: 1em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0em;
}

.onboarding-subscription-plans-sec .nav-link p{
    font-size: .8em;
    color: #62CE6E;
    opacity: .8;
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
}

.onboarding-subscription-plans-sec .nav-pills{
    border: 0.92px solid #A3ADB836;
    background-color: #F8F9FF;
    padding: 0.5em 1em;
    display: inline-flex;
    align-items: center;
    /* gap: 1em; */
}

.onboarding-subscription-plans-sec .nav-link.active h4{
    color: var(--secondary-color);
    font-size: 1em!important;
}

.onboarding-subscription-plans-sec .nav-link.active p{
    color: var(--secondary-color);
    font-size: .8em!important;
}

.onboarding-subscription-plans-card{
    border: 1px solid #EAEAEF;
    background-color: var(--secondary-color);
    border-radius: 20px;
}

.onboarding-subscription-plans-box{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 0.5em;
    margin-top: 1em;
}

.onboarding-subscription-plans-header-sec{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    /* background-color: #e7e6e6; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* padding: 0.5em; */
    padding-top: 1.5em;
}
.onboarding-subscription-plans-header-sec h3{
     font-size: 1em;
    font-weight: 500;
    margin-bottom: 0;
    color: #A0A0A0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}


.onboarding-subscription-plans-body-sec{
    padding: 1em;
}

.onboarding-subscription-plans-amount-sec {
    padding-bottom: 1em;
    display: flex;
    align-items: flex-end;
    gap: 0.5em;
    justify-content: center;
}

.onboarding-subscription-plans-amount-sec p{
    font-size: .8em;
    color: #62CE6E;
    opacity: .8;
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
}

.onboarding-subscription-plans-amount-sec h3{
    font-size: 1.2em;
    font-weight: 600;
    color: var(--tertiary-color);
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.onboarding-subscription-plans-amount-sec h3 span{
    color: #0B0B12DE;
    font-weight: 400;
    font-size: 0.5em;
}

.onboarding-subscription-free-plans-btn{
    border-radius: 10px!important;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    color: var(--tertiary-color)!important;
    border-color: #F9F2ED!important;
    background-color: #F9F2ED!important;
    text-decoration: none!important;
    display: inline-block;
    padding: 0.6em 2em!important;
    font-size: 0.9em!important;
    line-height: 25px;
    font-weight: 500!important;
    letter-spacing: .5px;
    width: 100%;
    padding-bottom: 1em!important;
}

.onboarding-subscription-free-plans-btn:after {
    content: "";
    width: 0;
    height: 8px;
    position: absolute;
    bottom: -1px;
    left: 0;
    border-radius: 0 0 5px 5px;
    transition: all 0.35s ease 0s;
    background-color: #fff;
  }
  
  .onboarding-subscription-free-plans-btn:hover:after {
   width: 100%;
  }

  .onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-free-plans-btn::after{
    background-color: var(--primary-color);
  }

/* .onboarding-subscription-plans-choose-btn-sec{
    padding-bottom: 1em;
} */

.onboarding-subscription-plans-info-list{
    margin-bottom: 1em;
}

.onboarding-subscription-plans-info-list li span{
    color: #50506B;
    font-weight: 300;
    font-size: 0.9em;
}

.onboarding-subscription-plans-info-list li{
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding-bottom: 1em;
}

/* .onboarding-subscription-plans-info-list li:last-child{
    padding-bottom: 0;
} */

.onboarding-subscription-plans-card.advance-plan-card{
    /* background-color:#151517; */
    background: linear-gradient(271.37deg, #FF6F6F -2.39%, #FFB179 98.67%);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-info-list li svg path{
    stroke: var(--secondary-color);
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-header-sec{
    /* background-color:#2F2F30; */
    color:var(--secondary-color);
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-header-sec h3{
    color: var(--secondary-color)!important;
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-amount-sec h3{
    color: var(--secondary-color)!important;
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-amount-sec h3 span{
    color: #FFFFFFDE;
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-amount-sec p{
    font-size: .8em;
    color: #62CE6E;
    opacity: .8;
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-amount-sec{
    display: flex;
    align-items: flex-end;
    gap: 0.5em;
    justify-content: center;
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-plans-info-list li span {
    color:var(--secondary-color);
}

.onboarding-subscription-plans-card.advance-plan-card .onboarding-subscription-free-plans-btn{
    color: var(--tertiary-color) !important;
    /* background: linear-gradient(89.12deg, #FFCD00 0.53%, #FF916F 96.47%); */
    background-color: var(--secondary-color)!important;
    border: 0;
    display: flex;
    align-items: center;
    gap: 0.3em;
    justify-content: center;
}

.onboarding-subscription-plans-info-list-sec{
    margin-top: 1em;
}

.onboarding-subscription-plans-info-list-sec p{
    color: var(--tertiary-color);
    margin-bottom: 0.8em;
    font-size: 0.95em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.onboarding-subscription-plans-info-list-sec p:last-child{
    margin-bottom: 0;
}

.onboarding-payment-method-footer-sec{
    padding-top: 1em;
}

.onboarding-payment-method-footer-sec .onboarding-btn{
    width: 100%;
}

.verification-item div{
    justify-content: center;
    gap: 1em;
}

.verification-item h4{
    text-align: center;
    margin-bottom: 1em!important;
    font-size: 1.1em!important;
}

.verification-item{
    margin-bottom: 1em!important;
}

.verification-item input {
    width: 3rem !important;
    height: 3rem;
    font-size: 1.5rem;
    border-radius:0px;
    border-color: rgba(50, 8, 159, 0.08);
    color: var(--primary-color);
   
}

.verification-item input:focus-visible{
    /* background-color: rgba(50, 8, 159, 0.08); */
    border-color: var(--primary-color)!important;
    outline-color:var(--primary-color)!important;
}

.verification-btn-sec {
    display: flex;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    margin-bottom: 1.5em;
    font-size: .9em;
    color: var(--quaternary-color);
    opacity: .8;
    font-weight: 400;
    line-height: 1.8;
}

.resend-link{
    color: var(--primary-color);
    font-weight: 500;
    outline: none;
    border: none;
    background: transparent;
}

.verify-btn-sec{
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-billings-pagination-sec ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
}

.search-btn {
    border: none;
    background: none;
}

.admin-ui-header-sec .navbar-toggler{
    /* padding: 0!important; */
    border:0!important;
}

.admin-ui-header-sec .navbar-toggler:focus{
    box-shadow: none!important;
}

.chat-img {
    width: 100%;
    height: 100%;
}

.btn-loader {
    width: 30px;
    height: 30px;
}

.static-page-card{
    width: 100%;
    /* background-image: url('./onboarding-right-bg-img.png'); */
    background-image: unset;
    background-repeat: no-repeat;
    position: relative;
    min-height: 100vh;
    background-position: bottom;
    background-size: cover;
    display: block;
    position: relative;
}

.static-page-card::before{
    position: absolute;
    content: "";
    mix-blend-mode: multiply;
    background: linear-gradient(180deg,#FF916F 0,#FF916F 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.static-page-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 2em 2em;
}

.static-page-content h1{
    font-size: 2em;
    font-weight: 500;
    line-height: 1.5;
    color: var(--secondary-color);
    position: relative;
    margin-bottom: 0.5em;
    text-align: center;
}

.static-page-content p{
    font-size: 0.95em;
    color: #FFFFFFB5;
    margin: 0;
    font-weight: 400;
    line-height: 1.8;
}

.static-page-list{
    margin-top: 2em;
}

.static-page-list svg{
    margin-top: 0.4em;
}

.static-page-list li{
    grid-template-columns: 40px auto;
    display: grid;
    padding-bottom: 1em;
    font-size: 0.95em;
    color: var(--secondary-color);
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
}

.static-page-list li:last-child{
    padding-bottom: 0;
}

.upload-preview-sec {
    background: rgba(50, 8, 159, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    gap: 20px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .onboarding-choose-solution-logo-icon{
    height: 2em;
    object-fit: contain;
    width: 8em;
  }

  .back-option-sec{
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: #252525;
    padding-top: 1em;
  }

  .import-webpage-modal .divider{
    /* background: var(--primary-color); */
    /* border: 0; */
    border: 0.1px dashed var(--btn-color);
    height: 1px;
    width: 50%;
    position: relative;
    margin: 30px auto;
  }

  .import-webpage-modal .divider:before {
    content: "";
    width: 15px;
    height: 10px;
    background: var(--btn-color);
    display: inline-block;
    border: 2px solid var(--btn-color);
    position: absolute;
    top: -5px;
    left: 50%;
    margin: 0 0 0 -6px;
}

.import-webpage-header-sec{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1em;
    align-items: center;
}

.upload-preview-sec.large-file {
    background: rgb(220 53 69 / 20%);
}

.file-size-error {
    font-size: 0.75em;
    font-weight: 400;
    color: red;
    margin-bottom: 0;
}
.ai-auto-note-area p{
    color: #666E81;
    font-size: .85em;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.8;
    /* margin-top: 1em; */
    flex: 1 1;
}
.ai-auto-note-area{
    display: flex;
    align-items: center;
    align-items: flex-start;
    margin-top: 1em;
    gap: 0.5em;
}

.onboarding-sec .onboarding-form .errorMsg {
    background-color: #e34f4f;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    height: 25px;
    line-height: 1;
    padding: 6px 8px;
    position: absolute;
    right: -15px;
    top: 8px;
    z-index: 99;
}

.efi-bot-auth-form-group{
    position: relative;
}

.onboarding-sec .onboarding-form .errorMsg:after {
    border-color: #0000 #e34f4f;
    border-style: solid;
    border-width: 0 6px 6px 0;
    bottom: -6px;
    content: "";
    display: block;
    position: absolute;
    right: 1.2em;
    width: 0;
    z-index: 99;
}

.onboarding-form .input-group-error .form-control {
    border-color: #e34f4f!important;
}

.onboarding-form .input-group-error .input-group-text{
    border-color: #e34f4f!important;
}