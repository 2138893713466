@-webkit-viewport {
    width: device-width;
  }
  
  @-moz-viewport {
    width: device-width;
  }
  
  @-ms-viewport {
    width: device-width;
  }
  
  @-o-viewport {
    width: device-width;
  }
  
  @viewport {
    width: device-width;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
  
  /* :root {
    --primary-color: #00195F;
    --secondary-color: #ffffff;
    --tertiary-color: #0F1623;
    --quaternary-color:#929292;
    --quinary-color: #394256;
    --senary-color: #3BD078;
    --tw-gradient-stops: #ddd6fe,#bbf7d0;
  } */
  
.landing-page-sec {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: 0.25s ease;
    transition: 0.25s ease;
    overflow-x: hidden;
    position: relative;
    color: var(--tertiary-color);
    background-color: var(--secondary-color);
    font-family: "Outfit", sans-serif!important;  
    font-size: 16px !important;
    letter-spacing: 0.8px;
  }
  
  .hamburger-menu {
    height: 2em;
  }
  
  .navbar-toggler {
    border: 0 !important;
    padding: 0 !important;
  }
  
  .navbar-toggler:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  
  /* img{
    max-width: 100%;
    height: auto;
    vertical-align: top;
  } */
  
  a,
  input,
  textarea {
    outline: 0 !important;
    box-shadow: none;
    text-decoration: none !important;
  }
  
  .no-border {
    border: 0 !important;
  }
  
  .pd-left-zero {
    padding-left: 0;
  }
  
  .no-padding {
    padding: 0 !important;
  }
  
  .no-margin-top {
    margin-top: 0 !important;
  }
  
  .no-margin {
    margin: 0 !important;
  }
  
  .no-margin-left {
    margin-left: 0;
  }
  
  .no-margin-right {
    margin-right: 0;
  }
  
  .padding-right-zero {
    padding-right: 0;
  }
  
  .btn-center {
    text-align: center;
    margin-bottom: 2em;
  }
  
  .no-margin-auto {
    margin: unset !important;
  }
  
  .border-right-zero {
    border-right: 0 !important;
  }
  
  .xs-padding {
    padding: 2em 0;
  }
  
  .sm-padding {
    padding: 4em 0;
  }
  
  .xs-padding-top {
    padding-top: 2em;
  }
  
  .space-mg {
    height: 1em;
  }
  
  .space-lg {
    height: 2em;
  }
  
  .mrg-btm-sec {
    margin-bottom: 2em;
  }
  
  .margin-btm-sm {
    margin-bottom: 1.5em;
  }
  
  .margin-btm-md {
    margin-bottom: 3em;
  }
  
  .margin-top-sm {
    margin-top: 3em;
  }
  
  .xl-btn {
    padding: 1.4em 2.2em !important;
    margin: 0 0.5rem;
  }
  
  .bg-grey {
    background-color: #f3f4f4;
  }
  
  .line-height-1 {
    line-height: 1;
  }
  
  .increase-font {
    font-size: 1.5em !important;
    font-weight: 800 !important;
  }
  
  .bg-gradient {
    background-image: linear-gradient(90deg, #00f3f7 0, #109df7 100%) !important;
  }
  
  /* .navbar-light .navbar-toggler-icon {
    background-image: url("../img/icons/mobile-toogle.svg") !important;
  } */
  
  .navbar-light .navbar-toggler {
    border: 1px solid var(--secondary-color) !important;
    color: var(--secondary-color) !important;
  }
  
  .navbar-light .navbar-toggler:focus {
    outline: 0 !important;
    box-shadow: none !important;
  }
  
  .logo {
    margin-right: 1em;
    max-width: 18em;
  }
  
  .logo-footer {
    max-width: 5em;
  }
  
  .height-auto {
    height: auto !important;
  }

  .section-title{
    margin-bottom: 2em;
  }
  
  
  .section-title h2 {
    font-size: 2.5em;
    font-weight: 500;
    color: var(--tertiary-color);
    position: relative;
    margin-bottom: 0.5em;
    text-align: center;
  }
  
  .section-title h3 {
    font-size: 2.5em;
    font-weight: 500;
    color: var(--tertiary-color);
    position: relative;
    margin-bottom: 0.5em;
    text-align: center;
  }
  
  .section-title h3 {
    font-size: 2.5em;
    font-weight: 500;
    color: var(--tertiary-color);
    position: relative;
    margin-bottom: 0.5em;
    text-align: center;
  }
  
  .section-title h4 {
    font-size: 2.5em;
    font-weight: 500;
    color: var(--tertiary-color);
    position: relative;
    margin-bottom: 0.5em;
    text-align: center;
  }
  
  .section-title p {
    font-size: 1rem;
    font-weight: 300;
    color: var(--quaternary-color);
    opacity: 0.8;
    line-height: 1.8;
    margin-bottom: 0.5em;
    text-align: center;
  }
  
  .section-title p+p+p{
    margin-bottom: 0;
  }
  
  /* img{
    width: 100%;
    display: block;
    max-width: 100%;
    height: auto;
  } */
  
  .default-btn {
    border-color: var(--primary-color);
    background: linear-gradient(89.12deg, #FFCD00 0.53%, #FF916F 96.47%);
    display: inline-block;
    padding: 0.8em 2em;
    border-radius: 8px !important;
    letter-spacing: 0.5px;
    border-width: 1px;
    color: var(--tertiary-color);
    text-decoration: none !important;
    font-size: 1.1em;
    line-height: 25px;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    font-weight: 500;
  }
  
  .default-btn:active,
  .default-btn:focus,
  .default-btn:hover {
    -webkit-transform: translate3d(0,-5px,0);
    -o-transform: translate3d(0,-5px,0);
    -ms-transform: translate3d(0,-5px,0);
    -moz-transform: translate3d(0,-5px,0);
    transform: translate3d(0,-5px,0);
    color: var(--tertiary-color);
    text-decoration: none !important;
    border-color: var(--primary-color);
    background: linear-gradient(89.12deg, #FFCD00 0.53%, #FF916F 96.47%);
  }
  
  .default-outline-btn {
    border: 1px solid var(--primary-color)!important;
    background-color: transparent;
    display: inline-block;
    padding: 0.8em 2em;
    border-radius: 15px !important;
    letter-spacing: 0.5px;
    color: var(--primary-color);
    text-decoration: none !important;
    font-size: 1.1em;
    line-height: 25px;
    font-weight: 500;
  }
  
  .default-outline-btn:active,
  .default-outline-btn:focus,
  .default-outline-btn:hover {
    border: 1px solid var(--primary-color)!important;
    background-color: transparent;
    color: var(--primary-color);
    text-decoration: none !important;
  }
  
  
  .white-btn-1 {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    display: inline-block;
    padding: 0.8em 2em;
    border-radius: 8px !important;
    letter-spacing: 0.5px;
    border-width: 1px;
    color: var(--tertiary-color);
    text-decoration: none !important;
    font-size: 1.1em;
    line-height: 25px;
    position: relative;
    -webkit-transition: .5s;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    transition: .5s;
    font-weight: 500;
  }
  
  .white-btn-1:active,
  .white-btn-1:focus,
  .white-btn-1:hover {
    -webkit-transform: translate3d(0,-5px,0);
    -o-transform: translate3d(0,-5px,0);
    -ms-transform: translate3d(0,-5px,0);
    -moz-transform: translate3d(0,-5px,0);
    transform: translate3d(0,-5px,0);
    color: var(--tertiary-color);
    text-decoration: none !important;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }
  
  .header-nav-center-1 {
    padding: 1em 0;
    background-color: transparent;
    position: relative;
  }
  
  .header-nav-center-1 .navbar .nav-item .nav-link {
    font-size: 1em;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--tertiary-color);
    opacity: 0.8;
    letter-spacing: 0.5px;
    padding: 0.5em 1em;
    position: relative;
    transition: transform 0.5s;
    padding-bottom: 0.5em;
  }
  
  .header-nav-center-1 .navbar .navbar-nav {
    gap: 1em;
    align-items: center;
  }
  
  .header-nav-center-1 .navbar .nav-item .nav-link:hover {
    color: var(--tertiary-color);
    opacity: 1;
  }
  
  .open-header-btn {
    /* background-color: var(--primary-color)!important; */
    background: linear-gradient(89.12deg, #FFCD00 0.53%, #FF916F 96.47%) !important;
    border-radius: 8px;
    color: var(--tertiary-color) !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    padding: 0.8em 1.5em !important;
    line-height: 2 !important;
    opacity: 1!important;
  }
  
  .open-header-btn:hover{
    -webkit-transform: translate3d(0,-5px,0);
    -o-transform: translate3d(0,-5px,0);
    -ms-transform: translate3d(0,-5px,0);
    -moz-transform: translate3d(0,-5px,0);
    transform: translate3d(0,-5px,0);
    color: var(--secondary-color);
    background-color: var(--primary-color)!important;
  }
  
  .margin-top-xl{
    margin-top: 3em;
  }
  
  .banner-img{
    width: 100%;
  }
  
  .banner-content h1 {
    font-size: 3.2em;
    color: var(--tertiary-color);
    line-height: 1.3;
    font-weight:600;
    margin-bottom: 0.4em;
    max-width: 600px;
  }
  
  .banner-content p {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 1.875rem;
    color: var(--quaternary-color);
    opacity: 0.8;
    line-height: 1.8;
    max-width: 450px;
  }
  
  .banner-content h5{
    font-size: 1.5em;
    color: var(--primary-color);
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  
  .banner-btn-sec {
    display: flex;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
  }
  
  .banner-info-sec {
    padding-top: 1.5em;
  }
  
  .banner-info-sec p {
    font-size: 1em;
    color: var(--quaternary-color);
    line-height: 1.8;
  }
  
  .banner-caption {
    min-height: 88vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2em;
    position: relative;
  }
  
  .home-banner-sec {
    /* background-image: url("../img/home-banner-bg.png"); */
    background: linear-gradient(180deg, #FFFFFF 0%, #fff7f5 100%);
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    width: 100%;
    display: block;
  }
  /* 
  .home-banner-sec:before {
    position: absolute;
    content: "";
    background: rgba(243, 94, 171, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  } */
  
  
  .header-dropdown .dropdown-menu {
    border-top: 4px solid #cc4783 !important;
    border: 0;
    padding: 0 !important;
  }
  
  .header-dropdown .dropdown-menu .dropdown-item {
    padding: 0.5em 1em !important;
  }
  
  .header-dropdown .dropdown-menu:last-child .dropdown-item:hover {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.37rem;
  }
  
  .navbar-brand h4{
    color: var(--tertiary-color);
    margin-bottom: 0;
    font-size: 1.75rem;
    font-weight: 500;
  }
  
  .meet-the-team-box{
    margin-top: 3em;
  }
  
  .meet-the-team-card{
    background-color: var(--secondary-color);
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    height: 100%;
    /* padding: 2em; */
  }
  
  .meet-the-team-info{
    padding: 1.5em;
  }
  
  .team-social-sec ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 1em;
    margin-top: 1.5em;
  }
  
  .meet-the-team-content h6 {
    font-size: 1em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 1em;
  }
  
  .meet-the-team-content h4 {
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.4em;
  }
  
  .meet-the-team-content p {
    font-size: 0.9em;
    color: var(--quaternary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .team-social-sec ul li a{
    width: 2.5em;
    height: 2.5em;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .team-social-sec ul li a i{
    color:var(--secondary-color);
    font-size: 1.3em;
  }
  
  .team-img{
    height: 20em;
    object-fit: cover;
    object-position: top;
    width: 100%;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  
  /* Contact Us */
  
  .contact-us-sec {
    padding-top: 4em;
  }
  
  .contact-us-img{
    width: 85%;
  }
  
  .contact-info h2 {
    font-size: 2.2em;
    font-weight: 500;
    color: var(--secondary-color);
  }
  
  .contact-info p {
    font-size: 1em;
    color: var(--secondary-color);
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .contact-us-form-box {
    background-color: #FAF6FF;
    border: 1px solid #CFCFCF;
    border-radius: 16px;
    padding: 2em;
    /* margin-bottom: 4em; */
  }
  
  .contact-us-box{
    padding-bottom: 4em;
  }
  
  .contact-us-form {
    margin-top: 2em;
    margin-bottom: 0;
  }
  
  .contact-us-form label {
    color: var(--tertiary-color);
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 0;
    opacity: 0.8;
  }
  
  .contact-us-form .form-control {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px!important;
    color: var(--primary-color) !important;
    border-radius: 0;
    height: 50px;
  }
  
  .contact-us-form textarea.form-control{
    height: auto;
  }
  
  .contact-us-form .form-control:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  
  .contact-us-form .form-control::placeholder {
    color: var(--primary-color) !important;
  }
  
  .contact-us-form-box h3 {
    font-size: 1.4em;
    font-weight: 500;
    color: var(--tertiary-color);
  }
  
  .contact-us-btn-1 {
    border-color: var(--tertiary-color);
    background-color: var(--tertiary-color);
    display: inline-block;
    padding: 0.5em 2em;
    border-radius: 50px !important;
    letter-spacing: 0.5px;
    border-width: 0px;
    color: var(--secondary-color);
    text-decoration: none !important;
    font-size: 1.1em;
    font-family: 'Sansation';
    line-height: 1.8;
  }
  
  .contact-us-btn-1:active,
  .contact-us-btn-1:focus,
  .contact-us-btn-1:hover {
    border-color: var(--tertiary-color);
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
    text-decoration: none !important;
  }
  
  .contact-us-submit-btn-sec {
    margin-top: 2em;
  }
  
  .contact-left-sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
  }
  
  .thank-you-sec {
    min-height: 50vh;
    padding: 4em 0;
  }
  
  .thank-you-sec img {
    max-width: 25em;
  }
  
  .thank-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    min-height: 50vh;
  }
  
  .thank-content h4 {
    font-size: 4em !important;
    font-weight: 500;
  }
  
  .thank-content p {
    font-size: 1.5em;
    color: var(--secondary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .banner-video-sec{
    position: relative;
  }
  
  .banner-video-sec::before{
    position: absolute;
    content: "";
    background: rgb(96 94 246 / 100%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    mix-blend-mode: overlay;
  }
  
  .benefit-box{
    margin-top: 3em;
  }
  
  .benefit-info h4 {
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.8em;
  }
  
  .benefit-info p {
    font-size: 0.9em;
    color: var(--quaternary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .benefit-icon-sec{
    margin-bottom: 0.8em;
  }
  
  .benefit-box .slick-prev {
    top: -20px;
    right: 48px;
    left: unset;
  }
  
  .benefit-box .slick-prev:before {
    content: "\f30a";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--tertiary-color);
  }
  
  .benefit-box .slick-next:before {
    content: "\f30b";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--tertiary-color);
  }
  
  .benefit-box .slick-next {
    right: 6px;
    top: -20px;
  }
  
  .benefit-box .benefit-card{
    margin-top: 2em;
  }
  
  .benefit-box .slick-track{
    display: flex;
    gap: 2em;
  }
  
  .growth-box{
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    gap: 2rem;
  }
  
  .growth-sec{
    background-color: var(--primary-color);
  }
  
  .quotes-sec{
    background-color: var(--tertiary-color);
  }
  
  .quotes-card{
    text-align: center;
  }
  
  .quotes-card p{
    font-size: 1.2em;
    color: var(--secondary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .growth-card h3{
    font-size: 1.8em;
    font-weight: 500;
    color: var(--secondary-color);
    margin-bottom: 0.5em;
    border-left: 1px solid var(--senary-color);
    padding-left: 0.8em;
  }
  
  .growth-card p{
    font-size: 0.9em;
    color: var(--secondary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
    padding-left: 1.5em;
  }
  
  .chat-platform-tab-sec{
    margin-top: 3em;
  }
  
  .chat-platform-tab-sec .nav-pills{
    justify-content: center;
    gap: 2em;
  }
  
  .chat-platform-tab-sec .nav-pills .nav-link.active {
    background: rgba(59, 208, 120, 0.08);
    color: var(--senary-color);
  }
  
  .chat-platform-tab-sec .nav-pills .nav-link{
    border: 1px solid #F0F0F0;
    color: var(--tertiary-color);
  }
  
  .chat-platform-tab-content{
    margin-top: 3em;
  }
  
  .chat-platform-list li h4{
    font-size: 1em;
    font-weight: 500;
    color: var(--quinary-color);
    margin-bottom: 0;
  }
  
  .chat-platform-list{
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1.5rem;
    margin-bottom: 1.5em;
  }
  
  .chat-platform-list li{
    display: flex;
    align-items: center;
    gap: 0.6em;
  }
  
  .chat-platform-tab-info h3{
    font-size: 2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.8em;
  }
  
  .chat-platform-tab-info p{
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 1.875rem;
    color: var(--quaternary-color);
    opacity: 0.8;
    line-height: 1.8;
  }
  
  .chat-platform-img{
    width: 70%;
  }
  
  .chat-platform-btn-sec{
    display: flex;
    align-items: center;
    gap: 1em;
    color: var(--tertiary-color);
  }
  
  .techstack-img{
    width: 100%;
  }
  
  
  
  .item-wrapper[data-v-e7cf1f6a] {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      cursor: pointer;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      height: 66px;
      -webkit-justify-content: center;
      justify-content: center;
      margin-bottom: 10px;
      overflow: hidden;
      padding-left: 30px;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
  
  }
  
  .progfill.active {
      display: block;
      height: 105px;
      margin-bottom: 25px;
      background-color: blue;
  }
  
  .progfill {
    width: 50px;
    height: 50px;
    background-color: red;
  }
  
  .why-choose-us-sec{
    background-color: #FAF9FE;
  }
  
  .why-choose-us-img-sec{
    text-align: right;
  }
  
  .why-choose-us-img{
    width: 70%;
  }
  
  .why-choose-us-box{
    margin-top: 3em;
  }
  
  .why-choose-us-card h4{
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.5em;
  }
  
  .why-choose-us-card p{
    font-size: 0.9em;
    color: var(--quaternary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .why-choose-us-card {
    margin-top: 2em;
  }
  
  .line {
    background: rgba(59,208,120,.2);
    height: 518px;
    top: 75px;
    width: 3px;
    position: absolute;
    left: 0;
  }
  
  line span {
    background: #3bd078;
    display: block;
    height: 105px;
    opacity: .79;
    top: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 100%;
    position:absolute;
    left: 0;
  }
  
  .why-choose-us-wrapper{
    position: relative;
    /* padding: 37px 0 40px 38px; */
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 2rem;
    row-gap: 1rem;
  }
  
  .why-use-icon-sec{
    margin-bottom: 0.8em;
  }
  
  .why-use-info h4{
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.8em;
  }
  
  .why-use-info p{
    font-size: 0.9em;
    color: var(--quaternary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .why-use-card{
    /* margin-top: 2em;  */
    background-color: #FAF6FF;
    /* border: 1px solid #CFCFCF; */
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    padding: 1.5em;
    text-align: center;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    min-height: 17em;
    align-items: center;
    justify-content: center;
  }
  
  .why-use-box{
    margin-top: 3em;
  }
  
  .faq-sec{
    background-color: #FAF9FE;
  }
  
  .faq-box{
    margin-top: 3em;
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 2rem;
  }
  
  .faq-card{
    background: #FFFFFF;
    box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 2em;
  }
  
  .faq-card h4{
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.8em;
  }
  
  .faq-card p{
    font-size: 0.9em;
    color: var(--quaternary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .blog-info h4{
    font-size: 1.2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0.8em;
  }
  
  .blog-info p{
    font-size: 0.9em;
    color: var(--quaternary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .blog-img-sec{
    margin-bottom: 0.8em;
  }
  
  .blog-card{
    text-align: center;
    margin-top: 2em;
  }
  
  .blog-box{
    margin-top: 3em;
  }
  
  .blog-btn-sec{
    margin-top: 0.8em;
  }
  
  .blog-img{
    height: 18em;
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
  
  .footer-sec{
    background: #010101;
    border-top: 0.8px solid rgba(248, 249, 252, 0.2);
    padding-top: 2em;
  }
  
  .footer-social-link{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 0;
    margin-top: 1.5em;
  }

  .footer-social-link  li a:hover svg{
    fill: var(--btn-color);
  }

  .team-social-sec ul li a:hover{
    background-color: var(--btn-color);
  }
  
  .footer-about-sec p{
    color: #929292;
    margin-bottom: 0;
    font-size: 1.2em;
  }
  
  .footer-about-sec p a{
    color: #929292;
    margin-left: 0.5em;
  }

  .footer-about-sec p a:hover{
    color: var(--btn-color);
  }
  
  .footer-widget h3{
    font-size: 1.2em;
    font-weight: 500;
    color: var(--secondary-color);
    margin-bottom: 1em;
  }
  
  .footer-widget p{
    font-size: 1em;
    color: #929292;
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .footer-link li a{
    color: var(--quaternary-color);
  }
  
  .footer-link li {
    padding-bottom: 1em;
  }
  
  .footer-link li:last-child{
    padding-bottom: 0;
  }
  
  .copyrights-box p{
    font-size: 1em;
    color: #929292;
    opacity: 0.8;
    margin: 0;
    font-weight: 500;
    line-height: 1.8;
    text-align: center;
  }
  
  .copyrights-list{
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .copyrights-list li a{
    font-size: 0.9em;
    color: #929292;
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
  }
  
  .copyrights-box{
    border-top: 1px solid rgba(248, 249, 252, 0.2);
    padding-bottom: 2em;
    padding-top: 2em;
    margin-top: 2em;
  }
  
  .copyrights-list{
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 1em;
    justify-content: flex-end;
  }
  
  .footer-logo h4{
    color: var(--secondary-color);
    font-size: 1.75rem;
    font-weight: 500;
    margin-bottom: 1em;
  }
  
  .about-sec{
    /* background-color: #f9f9f9; */
    background: linear-gradient(#fff7f5 , #fff);
    position: relative;
  }
  
  .about-card h2{
    font-size: 2.5em;
    font-weight: 500;
    color: var(--tertiary-color);
    position: relative;
    margin-bottom: 1.2em;
    text-align: center;
  }
  
  .about-card p{
    font-size: 1rem;
    font-weight: 300;
    margin-bottom:0rem;
    color: var(--quaternary-color);
    opacity: 0.8;
    line-height: 1.8;
  }
  
  .resp-mrg-btm{
    margin-bottom: 2em;
  }
  
  .cta-card{
    padding: 2em;
    background-color: var(--tertiary-color);
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content:space-between;
  }
  
  .cta-card p{
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom:0rem;
    color: var(--secondary-color);
    opacity: 0.8;
    line-height: 1.8;
    max-width: 800px;
  }
  
  .benefit-icon{
    width: 40px;
    height: 40px;
  }
  
  .footer-grid-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3em;
  }
  
  /* FAQ Section CSS */
  
  .faq-accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid rgba(1, 1, 1, 0.1);
    color: var(--tertiary-color);
  }
  
  .faq-accordion .accordion-item {
    border: none;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 7%);
  }
  
  .faq-accordion .accordion-item .accordion-button {
    border-bottom: 1px solid rgba(1, 1, 1, 0.1);
    font-size: 0.5em;
    font-weight: 500;
    color: var(--tertiary-color);
    padding: 1.3em;
    filter: drop-shadow(0 0 10px #eee);
    background-color: var(--secondary-color);
  }
  
  .faq-accordion .accordion-item .accordion-button:focus {
    box-shadow: none;
  }
  
  .faq-accordion .accordion-item .accordion-body {
    font-size: 1rem;
    font-weight: 300;
    color: var(--quaternary-color);
    line-height: 1.8;
    margin: 0;
  }
  
  /* .faq-accordion .accordion-button:not(.collapsed)::after {
    background-image: url(../img/faq/minus.svg);
    transform: unset;
  }
  
  .faq-accordion .accordion-button::after {
    background-image: url(../img/faq/plus.svg);
  } */
  
  .pricing-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    padding: 0 8em;
  }
  
  .pricing-card {
    padding: 2em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background-color: var(--secondary-color);
  }
  
  .pricing-pro-card {
    padding: 2em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background-color: var(--primary-color);
  }
  
  .pricing-product-icon-sec {
    width: 4em;
    height: 4em;
    border-radius: 16px;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pricing-border-icon {
    /* border: 3px solid var(--secondary-color);
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em; */
  }
  
  .pricing-product-name-sec {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .pricing-product-name h3 {
    font-size: 2em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 0;
  }
  
  .buy-btn {
    width: 100%;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    display: inline-block;
    padding: 1em 2em;
    border-radius: 16px !important;
    letter-spacing: 0.5px;
    border-width: 1px;
    color: var(--secondary-color);
    text-decoration: none !important;
    font-size: 1.1em;
    text-align: center;
  }
  
  .buy-btn:hover,
  .buy-btn:focus,
  .buy-btn:active {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
  
  .pricing-amount-sec {
    font-size: 3em;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 0;
    padding: 0.5em 0;
  }
  
  .pricing-list-sec {
    margin-bottom: 0;
  }
  
  .pricing-list-sec li {
    padding-bottom: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: #000;
  }
  
  .pricing-list-sec li:last-child {
    padding-bottom: 0;
  }
  
  .pricing-btn-sec {
    margin-top: 2em;
  }
  
  .pricing-amount-sec span {
    color: rgba(0, 0, 0, 0.27);
  }
  
  .pricing-pro-card .pricing-product-icon-sec {
    background-color: var(--secondary-color);
  }
  
  .pricing-pro-card .pricing-border-icon {
    border-color: var(--tertiary-color);
  }
  
  .pricing-pro-card .pricing-product-name h3 {
    color: var(--secondary-color);
  }
  
  .pricing-pro-card .pricing-amount-sec span {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .pricing-pro-card .pricing-amount-sec {
    color: var(--secondary-color);
  }
  
  .pricing-pro-card .pricing-list-sec li {
    color: var(--secondary-color);
  }
  
  .pricing-pro-card .buy-btn {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--primary-color);
  }
  
  .pricing-pro-card .buy-btn:hover,
  .pricing-pro-card .buy-btn:focus,
  .pricing-pro-card .buy-btn:active {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--primary-color);
  }
  
  .cta-banner-card{
    background-image: linear-gradient(to bottom right,var(--tw-gradient-stops));
    box-shadow:  0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1);
    animation: bounce 1s infinite;
    padding: 2em;
    border-radius: 16px;
  }
  
  
  @keyframes bounce{0%,to{transform:translateY(-25%);animation-timing-function:cubic-bezier(.8,0,1,1)}50%{transform:none;animation-timing-function:cubic-bezier(0,0,.2,1)}}
  
  .cta-banner-card h4 {
    font-size: 1.4em;
    font-weight: 500;
    color: var(--tertiary-color);
    margin-bottom: 1em;
    text-align: center;
  }
  
  .cta-banner-card p{
    font-size: 1em;
    color: var(--quaternary-color);
    opacity: 0.8;
    margin: 0;
    font-weight: 300;
    line-height: 1.8;
    text-align: center;
  }

  /* Responsive CSS */

  @media (max-width: 575.98px) {}

@media (min-width: 576px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {}

@media (min-width: 992px) {}

@media (min-width: 1440px) {}

@media (max-width: 1199.98px) {
.logo{
    max-width: 12em;
}
.sm-padding {
    padding: 3em 0;
}
}

@media (max-width: 991.98px) {
    .resp-mrg-btm-md{
        margin-bottom: 2em;
    }
    .growth-box{
        grid-template-columns: repeat(2, 1fr);
    }
    .chat-platform-tab-content{
        text-align: center;
    }
    .why-choose-us-img-sec{
        text-align: center;
    }
    .line{
        height: 450px;
    }
    .benefit-box {
        margin-top: 2em;
    }
    .chat-platform-tab-sec{
        margin-top: 2em;
    }
    .why-choose-us-box{
        margin-top: 2em;
    }
    .why-use-box{
        margin-top: 2em;
    }
    .meet-the-team-box{
        margin-top: 2em;
    }
    .copyrights-box{
        text-align: center;
    }
    .copyrights-list{
        justify-content: center;
    }
    .cta-card{
        flex-direction: column;
        gap: 1em;
        text-align: center;
    }
}


@media (max-width: 767.98px) {
    .footer-widget-box{
        grid-template-columns: repeat(1, 1fr);
    }
    .banner-content h1 {
        font-size: 2em;
    }
    .banner-content p {
        font-size: 0.9rem;
    }
    .about-card h2, .section-title h2, .section-title h3, .section-title h4{
        font-size: 2em;
    }
    .news-letter-item .form-control{
        min-width: 100%;
    }
    .banner-content{
        text-align: center;
    }
    .banner-btn-sec{
        justify-content: center;
    }
    .ready-to-login-sec{
        flex-direction: column;
        gap: 1em;
    }
    .footer-address-card p{
        margin: auto;
    }
    .footer-about-card{
        text-align: center;
    }
    .footer-social-card{
        text-align: center;
    }
    .team-social-sec ul{
        justify-content: center;
    }
    .footer-widget-copyright-sec p{
        text-align: center;
    }
    .growth-card h3{
        padding-left: 0.5em;
    }
    .growth-card p{
        padding-left: 1em;
    }
    .growth-box{
        gap:1em;
    }
    .chat-platform-list{
        grid-template-columns: repeat(1, 1fr);
    }
    .section-title h2 {
        font-size: 2em;
    }
    .sm-padding {
        padding: 2em 0;
    }
    .why-choose-us-wrapper{
        padding: 0;
        grid-template-columns: repeat(1, 1fr);
    }

    .why-choose-us-card{
        border: 1px solid #F0F0F0;
        background: rgba(59, 208, 120, 0.08);
        color: var(--senary-color);
        padding: 2em;
        border-radius: 16px;
    }
    .line{
        display: none;
    }
    .faq-box{
        margin-top: 2em;
    }
    .blog-box{
        margin-top: 2em;
    }
    .copyrights-list{
        flex-wrap: wrap;
    }
    .footer-sec{
        padding-top: 2em;
    }
    .footer-about-sec{
        text-align: center;
    }
    .footer-social-link{
        justify-content: center;
    }
    .footer-widget{
        text-align: center;
    }
    .resp-mrg-btm-xs{
        margin-bottom: 2em;
    }
    .cta-card{
        flex-direction: column;
        gap: 1em;
        text-align: center;
    }
    .footer-grid-box{
        flex-direction: column;
        gap: 2em;
        padding-left: 0;
    }
    .pricing-box{
        grid-template-columns: repeat(1, 1fr);
        padding: 0 0em;
    }
    .section-title h4 {
        font-size: 2em;
    }
    .about-card p, .cta-card p{
        font-size: 0.9em;
    }
    .quotes-card p{
        font-size: 1em;
    }
    .thank-you-sec img {
        max-width: 20em;
    }
}

@media (max-width: 575.98px) {}


/* Large Device CSS */

@media screen and (min-width: 1400px) {
    .container {
      width: 1320px;
      max-width: 100%;
    }
  }
  @media screen and (min-width: 1600px) {
    .container {
      width: 1320px;
      max-width: 100%;
    }
  }
  @media screen and (min-width: 1900px) {
    .container {
      width: 1520px;
      max-width: 100%;
    }
  }