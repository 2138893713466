
.admin-ui-delay-body-sec{
    /* For Developing Perpose   */
    /* margin-top: 83px;
    max-width: 800px;
    margin:  100px  auto 0; */
    margin-top: 2em;
}
.new-delay-table table td,.new-delay-table table th{
    /* vertical-align: middle; */
    padding: 1em;
}
.new-delay-table table th{
  color:var(--tertiary-color);
}
.new-delay-table table  tr:hover{
    background-color: #FFF6DD;
}
.new-delay-table table td:first-child p{
    color: #292B30;
    font-size: 0.85em;
    line-height: 1.8;
    font-weight: 400;
    margin-bottom: 0em!important;
    width: calc(100% - 40px);
    word-break: break-all
}
.new-delay-table table td:first-child {
    border-right-width: 0;
}
.new-delay-table table td:nth-child(2) {
    border-left-width: 0;
}
.new-delay-table table th:first-child {
    border-right-width: 0;
}
.new-delay-table table th:nth-child(2) {
    border-left-width: 0;
}
.delay-action{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}
.edit-action button{
    background-color: #FFF6DD!important;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none!important;
}
.delete-action button{
    background-color: transparent!important;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none!important;
}

/* Modal  */
.delay-text-modal .modal-header{
    border-bottom: none!important;
}
.delay-text-modal .modal-footer{
    border-top: none!important;
}
.delay-text-modal .modal-title{
    font-size: 1.1em;
}
.delay-text-modal .modal-body{
    /* padding-top: 0!important; */
    padding-bottom: 0!important;
}
.delay-text-modal form .form-label{
    font-size: 0.9em;
    font-weight: 400;
    color: var(--quaternary-color);
    line-height: 1.5;
}
.delay-text-modal .form-control{
    border-radius: 0!important;
}
.delay-text-modal .form-control:focus{
    border: 1px solid #F3F2ED!important;
    box-shadow: unset!important;
}
.delay-text-modal .form-control::placeholder{
    font-weight: 0.7em;
    font-weight: 400;
    color: #8F9899;
}
.delay-text-modal .modal-footer button{
flex: 1 1;
text-align: center;
font-size: 0.8em!important;
justify-content: center;
}
.hidden{
    display: none;
}
.show{
    display: block;
}
.new-chat-customize-sec {
    margin-top: 2em;
}
.btn-grid-auto{
    grid-template-columns: auto!important;
  }
  .new-source-script-header-left-sec .btn:first-child:active{
    border: none!important;
  }
  .new-source-script-header-left-sec button{
    color:#212529;
    display: flex;
    gap: 0.5em;
    align-items: center;
    font-size: 0.9em;
    font-weight: 500;
  }
  .new-source-script-header-left-sec button:hover{
    color:#212529;
  }
  .delay-text-modal .onboarding-form{
  padding-top: 0em!important;
  }

  .btn:focus-visible{
    box-shadow:none!important;
  }